@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.spin {
  animation: spin 0.3s linear;
}

.rotate-180 {
  transform: rotate(180deg);
}

@keyframes reverse-spin {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.reverse-spin {
  animation: reverse-spin 0.3s linear;
}

::selection {
  color: #9DE0D1;
}

h4::selection {
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #C9E4DE;
  border-radius: 5px;
  background-size: contain;
}

::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  margin: 10px 0;
}

#background {
  background-image: url('../public/mockupBlocks.svg');
  background-repeat: no-repeat;
  animation: moveBackground 5s linear infinite;
}

@media (max-width: 992px) {
  #background {
    background-image: none;
  }
}

body {
  min-height: 100vh;
  position: relative;
}

#navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Adjust this value as needed */
}

.word {
  animation: slide 2s infinite;
}

@keyframes slide {
  0% { transform: translateY(-100%); }
  50% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}